document.addEventListener('DOMContentLoaded', () => {
    const methods = document.querySelectorAll(".js-method");
    const photos = document.querySelectorAll('.js-methods-photo');

    const init = () => {
        const options = {
            root: null,
            threshold: 0.5 // 50% elementu musi być widoczne, aby aktywować
        }

        let activeIndex = -1; // Aby śledzić, który element jest aktywny

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const newIndex = Array.from(methods).indexOf(entry.target);

                    // Tylko zmieniaj, jeśli nowy indeks jest inny
                    if (newIndex !== activeIndex) {
                        if (activeIndex >= 0) {
                            photos[activeIndex].classList.remove('is-active');
                        }
                        photos[newIndex].classList.add('is-active');
                        activeIndex = newIndex;
                    }
                }
            });
        }, options);

        methods.forEach(card => observer.observe(card));
    }

    if (methods.length > 0) {
        init();
    }
}, false);