import Swiper from 'swiper';
import { FreeMode, Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper.scss';

document.addEventListener('DOMContentLoaded', () => {
    
    const power = document.querySelector('.js-power'),
          testimonials = document.querySelector('.js-testimonials');

    const powerCarousel = () => {
        let mediaQuery = '(min-width: 640px)',
            mediaQueryList = window.matchMedia(mediaQuery),
            swiper;

        const start = () => {
            swiper = new Swiper(power, {
                modules: [FreeMode, Navigation],
                freeMode: true,
                navigation: {
                    nextEl: document.querySelector('.js-power-nav').querySelector('.swiper-button-next'),
                    prevEl: document.querySelector('.js-power-nav').querySelector('.swiper-button-prev'),
                },
                slidesPerView: 'auto',
                speed: 500
    	    });
        }

        mediaQueryList.addEventListener('change', event => {
            event.matches ? start() : swiper.destroy();
        })

        mediaQueryList.matches ? start() : false;
    }
    
    const testimonialsCarousel = () => {
        const pagination = testimonials.querySelector('.js-pagination');
        const swiper = new Swiper(testimonials, {
            modules: [Navigation],
            centeredSlides: true,
            on: {
                init: (e) => {
                    let all;
                    if (e.slides.length < 2) {
                        pagination.style.display = 'none'
                    } else {
                        e.slides.length < 10 ? all = '0' + e.slides.length : all = e.slides.length;
                        pagination.querySelector('.js-all').innerHTML = all;
                        pagination.querySelector('.js-current').innerHTML = '01';
                    }
                },
                activeIndexChange: (e) => {
                    let active = e.activeIndex + 1;         
                    active < 10 ? pagination.querySelector('.js-current').innerHTML = '0' + active :
                                   pagination.querySelector('.js-current').innerHTML = active;
                }
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            speed: 500,
        });
    }
    
    window.carousels = () => {
        power ? powerCarousel() : false;
        testimonials ? testimonialsCarousel() : false;
    }

}, false)
