import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export function speech() {
    setTimeout(function() {
        ScrollTrigger.refresh();
        gsap.from('.js-video', {
            scrollTrigger: {
            trigger: '.js-video',
            scrub: 2.2,
            start: 'top bottom',
            end: 'top center'
        },      
        xPercent: 15
    });

    }, 100)
}
