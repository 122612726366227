document.addEventListener('DOMContentLoaded', () => {
    const cards = Array.from(document.querySelectorAll('.js-card'));
    let currentFlippedCard = null;

    const onScroll = () => {
        const viewportHeight = window.innerHeight;
        const viewportTop = window.scrollY;
        const viewportBottom = viewportTop + viewportHeight;
        const viewportMiddle = viewportTop + viewportHeight / 2;

        let newFlippedCard = null;
        let nearestCardDistance = Infinity;

        // Sprawdź widoczność kart
        cards.forEach(card => {
            const rect = card.getBoundingClientRect();
            const cardTop = rect.top + window.scrollY;
            const cardBottom = cardTop + rect.height;
            const cardMiddle = cardTop + rect.height / 2;
            
            console.log(cardMiddle);

            // Sprawdź, czy karta jest w widoku przeglądarki
            if (cardBottom > viewportTop && cardTop < viewportBottom) {
                // Oblicz odległość środka karty od środka okna
                const distanceFromMiddle = Math.abs(cardMiddle - viewportMiddle);

                // Zaktualizuj najbliższą kartę
                if (distanceFromMiddle < nearestCardDistance) {
                    nearestCardDistance = distanceFromMiddle;
                    newFlippedCard = card;
                }
            }
        });

        // Sprawdź, czy najbliższa karta jest blisko środka
        if (newFlippedCard) {
            // Dodaj klasę `is-flipped` do nowej karty, jeśli jej środek jest blisko środka widoku
            if (Math.abs(newFlippedCard.getBoundingClientRect().top + window.scrollY + newFlippedCard.offsetHeight / 2 - viewportMiddle) <= viewportHeight / 4) {
                if (currentFlippedCard && currentFlippedCard !== newFlippedCard) {
                    currentFlippedCard.classList.remove('is-flipped');
                }
                newFlippedCard.classList.add('is-flipped');
                currentFlippedCard = newFlippedCard;
            } else {
                // Usuń klasę `is-flipped` z karty, jeśli jest zbyt daleko od środka
                if (currentFlippedCard === newFlippedCard) {
                    newFlippedCard.classList.remove('is-flipped');
                    currentFlippedCard = null;
                }
            }
        }

        // Usuń klasę `is-flipped` z kart, które są całkowicie poza widokiem
        cards.forEach(card => {
            const rect = card.getBoundingClientRect();
            const cardTop = rect.top + window.scrollY;
            const cardBottom = cardTop + rect.height;

            if (cardBottom < viewportTop || cardTop > viewportBottom) {
                card.classList.remove('is-flipped');
            }
        });
    };

    // Dodajemy nasłuchiwanie na zdarzenie scroll
    window.addEventListener('scroll', onScroll);

    // Wywołaj sprawdzenie początkowe
    onScroll();
});
/*
document.addEventListener('DOMContentLoaded', () => {
    const cards = document.querySelectorAll('.js-card');

    const init = () => {
        const options = {
            root: null, // Oznacza viewport
            threshold: 0.5 // 50% elementu musi być widoczne, aby aktywować
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.intersectionRatio >= 0.5) {
                    entry.target.classList.add('is-flipped');
                } else {
                    entry.target.classList.remove('is-flipped');
                }
            });
        }, options);

        cards.forEach(card => observer.observe(card));       
    };

    if (cards.length > 0) {
        init();
    }
}, false);
*/