document.addEventListener('DOMContentLoaded', () => {
    const slogans = document.querySelectorAll('.o-widget__slogans li');
    let currentIndex = 0;
    const intervalTime = 2500; // Synchronizacja z czasem trwania animacji

    const showSlogan = () => {
        slogans.forEach((slogan, index) => {
            slogan.classList.remove('from-top');
            slogan.classList.remove('from-bottom');
            slogan.style.opacity = '0'; // Ukryj wszystkie
            slogan.style.visibility = 'hidden'; // Upewnij się, że wszystkie są niewidoczne
        });

        const currentSlogan = slogans[currentIndex];
        currentSlogan.style.opacity = '1'; // Pokaż aktualny
        currentSlogan.style.visibility = 'visible'; // Upewnij się, że jest widoczny
        currentSlogan.style.setProperty('--animation-delay', '0s'); // Ustaw animację

        // Dodajemy klasy `from-top` lub `from-bottom` w zależności od indeksu
        if (currentIndex % 2 === 0) {
            currentSlogan.classList.add('from-top');
            currentSlogan.classList.remove('from-bottom');
        } else {
            currentSlogan.classList.add('from-bottom');
            currentSlogan.classList.remove('from-top');
        }

        currentIndex = (currentIndex + 1) % slogans.length; // Przejdź do następnego
    };

    showSlogan(); // Pokaż pierwszy slogan

    setInterval(showSlogan, intervalTime); // Ustaw interwał na czas trwania animacji
});
