import css from './sass/style.scss';

require('./js/anims')
require('./js/cards')
require('./js/carousels')
require('./js/init')
require('./js/methods')
require('./js/polyfills')
require('./js/sticky')
require('./js/widget')
