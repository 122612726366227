import stickybits from 'stickybits';

document.addEventListener('DOMContentLoaded', () => {

    const el = document.querySelectorAll('.js-sticky');

    const init = () => {
        let mediaQuery = '(min-width: 768px)',
            mediaQueryList = window.matchMedia(mediaQuery);
        
        const start = function(offset) {
            for (let i = 0; i < el.length; i ++) {
                let stickybit = stickybits(el[i], {
    	            stickyBitStickyOffset: offset
                });
            }
        }
        
        mediaQueryList.addEventListener('change', event => {
            event.matches ? start(140) : start(20);
        })

        mediaQueryList.matches ? start(140) : start(20);
    };

    window.addEventListener('load', () => {
        el.length > 0 ? init() : false;
    })

}, false);
