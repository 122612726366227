import { gsap, Power1 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export function stickers() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.timeline({
        scrollTrigger: {
            trigger: ".js-stickers",
            start: "top top",
            end: "+=200%",
            scrub: 1.2,
            pin: true,
            pinSpacing: true,
        }
    })
    .to(".o-sticker--01", { yPercent: -100 })
    .to(".o-sticker--02", { yPercent: -100 });
}

